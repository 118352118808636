import Link from 'components/Link'
import PropTypes from 'prop-types'

const ArticleLink = ({ article = {}, children, routeHandler }) => {
  const href = {
    pathname: '/_render/article',
    query: {
      type: 'article',
      entityUuid: article.entityUuid,
    },
  }

  return (
    <Link
      href={href}
      as={article.urlAlias}
      routerMiddleware={(next) => (routeHandler ? routeHandler(next) : next())}
    >
      {children}
    </Link>
  )
}

ArticleLink.propTypes = {
  article: PropTypes.object.isRequired,
}

export default ArticleLink

import forEach from 'lodash/forEach'
import get from 'lodash/get'
import trim from 'lodash/trim'

export function description2PlainText(description) {
  let text = ''
  forEach(description, (item) => {
    forEach(item.children, (child) => {
      if (get(child, 'type') === 'text' && get(child, 'data')) {
        text += `${trim(get(child, 'data'))} `
      } else if (get(child, 'children')) {
        text += `${description2PlainText([{ children: get(child, 'children') }])} `
      }
    })
  })
  return trim(text)
}

export function isEmailValid(email) {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)
}

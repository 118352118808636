import ShareButtonFacebook from 'components/Share/Button/ShareButtonFacebook'
import ShareButtonLink from 'components/Share/Button/ShareButtonLink'
import ShareButtonTelegram from 'components/Share/Button/ShareButtonTelegram'
import ShareButtonTwitter from 'components/Share/Button/ShareButtonTwitter'
import ShareButtonWhatsapp from 'components/Share/Button/ShareButtonWhatsapp'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { color, remCalc } from 'styles/mixins'

const Buttons = {
  facebook: ShareButtonFacebook,
  twitter: ShareButtonTwitter,
  whatsapp: ShareButtonWhatsapp,
  telegram: ShareButtonTelegram,
  link: ShareButtonLink,
}

const StyledWrapper = styled.div`
  width: ${remCalc(32)};
  height: ${remCalc(32)};
  border-radius: 50%;
  display: flex;

  & > * {
    display: block;
    flex: 1;
    color: ${color.white};
    justify-content: center;
    align-items: center;
    text-decoration: none;
  }
`

const ShareButton = ({ type, shareItem, className }) => {
  const Button = Buttons[type]
  return (
    <StyledWrapper className={className}>
      <Button shareItem={shareItem} />
    </StyledWrapper>
  )
}

ShareButton.propTypes = {
  type: PropTypes.string.isRequired,
  shareItem: PropTypes.object.isRequired,
}

export default ShareButton

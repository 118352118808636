import ScriptManager from 'lib/script-manager'
import get from 'lodash/get'
import { useStore } from 'store'

const withClipboardLogic = (WrappedComponent) => {
  const WithClipboardLogic = (props) => {
    const namespace = 'clipboardjs'
    const { externalScriptsState } = useStore()
    const { loaded = false } = get(externalScriptsState, `state[${namespace}]`, {})

    const setScriptStatus = (loaded) => {
      const script = {}
      script[namespace] = { loaded }
      externalScriptsState.dispatch(script)
    }

    const options = {
      namespace,
      url: '/yp/assets/js/clipboard.min.js',
      isDefer: true,
      isAsync: false,
    }

    ScriptManager.loadScript(options).then((loaded) => {
      if (loaded) {
        setScriptStatus(loaded)
      }
    })

    return <WrappedComponent scriptLoaded={loaded} {...props} />
  }
  return WithClipboardLogic
}
export default withClipboardLogic

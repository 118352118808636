import withTwitterLogic from 'hocs/with-twitter-logic'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import TwitterOIcon from 'public/assets/svg/twitter-o.svg'

const ShareButtonTwitter = ({ shareItem }) => {
  const shareText = get(shareItem, 'text')
  const shareUrl = get(shareItem, 'url')

  const shareHref =
    shareText && shareUrl
      ? `https://twitter.com/intent/tweet?text=${shareText}&url=${shareUrl}&related=&via=youngposthk`
      : false

  return (
    <a
      className="twitter share-button"
      target="_blank"
      rel="noopener noreferrer"
      data-size="large"
      title={get(shareItem, 'text')}
      href={shareHref}
    >
      <TwitterOIcon />
    </a>
  )
}

ShareButtonTwitter.propTypes = {
  shareItem: PropTypes.object.isRequired,
}

export default withTwitterLogic(ShareButtonTwitter)

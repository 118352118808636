import get from 'lodash/get'
import PropTypes from 'prop-types'
import WhatsappOIcon from 'public/assets/svg/whatsapp-o.svg'

const ShareButtonWhatsapp = ({ shareItem }) => {
  const shareText = get(shareItem, 'text')
  const shareUrl = get(shareItem, 'url')

  const shareHref =
    shareText && shareUrl ? `whatsapp://send?text=${shareText}%0A%0A${shareUrl}` : false

  return (
    <a href={shareHref}>
      <WhatsappOIcon />
    </a>
  )
}

ShareButtonWhatsapp.propTypes = {
  shareItem: PropTypes.object.isRequired,
}

export default ShareButtonWhatsapp

import Element from 'components/Common/Element'
import withArticleLevel from 'hocs/article-level/with-article-level'
import { richTextRenderer } from 'lib/rich-text-renderer'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import uniqueId from 'lodash/uniqueId'
import PropTypes from 'prop-types'
import React from 'react'

const componentRenderer = ({ type, children = [], attribs, mode }) => {
  switch (mode) {
    case 'plain': {
      switch (type) {
        default: {
          return children
        }
      }
    }
    default: {
      switch (type) {
        default:
          return React.createElement(
            Element,
            { key: uniqueId('subheading-'), type, attribs },
            children,
          )
      }
    }
  }
}

const Subheading = ({ node, className, mode = 'plain' }) => {
  const { subHeadline = [] } = node

  const renderedContents = map(subHeadline, (item) => {
    return richTextRenderer({ ...item, componentRenderer, mode })
  })

  return <>{!isEmpty(renderedContents) && <div className={className}>{renderedContents}</div>}</>
}

Subheading.propTypes = {
  node: PropTypes.object.isRequired,
}

export default withArticleLevel(Subheading)

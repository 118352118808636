import gql from 'graphql-tag'

const UPSERT_NEWSLETTER = gql`
  mutation upsertNewsletterSubscriber($input: NewsletterSubscriberInput!) {
    upsertNewsletterSubscriber(input: $input) {
      entity {
        ... on SimplenewsSubscriber {
          mail
          subscriptions {
            status
            source
            entity {
              entityLabel
            }
          }
        }
      }
      errors
      violations {
        path
        message
        code
      }
    }
  }
`

module.exports = {
  UPSERT_NEWSLETTER,
}

function getPropTypeError(type, propName, componentName) {
  return new Error(`${type} prop ${propName} supplied to \`${componentName}\`. Validation failed.`)
}

function createPropType(type, validator, payload) {
  function propType(props, propName, componentName) {
    if (!validator(props[propName], payload)) {
      return getPropTypeError(type, propName, componentName)
    }
  }
  Object.defineProperty(propType, 'name', { value: type })

  function optional(props, propName, componentName) {
    var prop = props[propName]
    if (prop === null || prop === void 0) {
      return
    }
    return propType(props, propName, componentName)
  }
  optional.isRequired = propType

  return optional
}

module.exports = createPropType

import { GET_QUEUE_ITEMS_UUID_BY_NAME } from 'apollo/queries/cs'
import compact from 'lodash/compact'
import get from 'lodash/get'
import map from 'lodash/map'
import uniq from 'lodash/uniq'

import useFetchQueueItems from './hook-fetch-queue-items'

const useFetchQueueItemsUuid = ({
  queueName,
  offset = 0,
  limit = 5,
  excludeEntityUuids,
  ssr = true,
  isLazy = false,
}) => {
  const overrideGetContext = (data) => {
    const result = map(get(data, 'queue.items', []), 'entityUuid')
    return result
  }
  const overrideConcatContext = (stateDataContext, newDataContext) =>
    uniq(compact([...stateDataContext, ...newDataContext]))

  return useFetchQueueItems({
    overrideQuery: GET_QUEUE_ITEMS_UUID_BY_NAME,
    overrideGetContext,
    overrideConcatContext,
    queueName,
    offset,
    limit,
    excludeEntityUuids,
    ssr,
    isLazy,
  })
}

export default useFetchQueueItemsUuid

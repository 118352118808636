import withClipboardLogic from 'hocs/with-clipboard-logic'
import get from 'lodash/get'
import has from 'lodash/has'
import PropTypes from 'prop-types'
import CopylinkOIcon from 'public/assets/svg/copylink-o.svg'
import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { color, fontFamily, remCalc } from 'styles/mixins'

const StyledLink = styled.a`
  position: relative;
`

const StyledTooltip = styled.div`
  top: 0;
  transition: opacity 0.6s ease-in-out;
  font-family: ${fontFamily.roboto};
  font-size: ${remCalc(13)};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  transform: translateY(-100%);
  opacity: ${(props) => (props.show ? 1 : 0)};
`

const StyledTooltipMessage = styled.div`
  display: block;
  background-color: ${color['black-50']};
  padding: ${remCalc(3, 10)};
  border-radius: ${remCalc(5)};
`

const StyledTooltipArrowDown = styled.div`
  width: 0;
  height: 0;
  border-left: ${remCalc(8)} solid transparent;
  border-right: ${remCalc(8)} solid transparent;
  border-top: ${remCalc(6)} solid ${color['black-50']};
`

const ShareButtonLink = ({ shareItem, scriptLoaded }) => {
  const shareUrl = get(shareItem, 'url')
  const linkRef = useRef(null)
  const [showTooltip, setShowTooltip] = useState(false)
  const [clipboardInstance, setClipboardInstance] = useState(null)

  const onClick = (e) => e.preventDefault()

  useEffect(() => {
    const element = linkRef.current
    if (element && scriptLoaded && has(window, 'ClipboardJS')) {
      const clipboard = new window.ClipboardJS(element)
      setClipboardInstance(clipboard)
    }
  }, [scriptLoaded])

  useEffect(() => {
    if (clipboardInstance) {
      clipboardInstance.on('success', () => {
        setShowTooltip(true)
        setTimeout(() => {
          setShowTooltip(false)
        }, 2000)
      })
      clipboardInstance.on('error', () => {
        setShowTooltip(false)
      })
    }
  }, [clipboardInstance])

  return (
    <StyledLink
      ref={linkRef}
      className="copylink"
      onClick={onClick}
      href="/"
      data-clipboard-text={shareUrl}
    >
      <StyledTooltip show={showTooltip}>
        <StyledTooltipMessage>Copied</StyledTooltipMessage>
        <StyledTooltipArrowDown />
      </StyledTooltip>
      <CopylinkOIcon />
    </StyledLink>
  )
}

ShareButtonLink.propTypes = {
  shareItem: PropTypes.object.isRequired,
  scriptLoaded: PropTypes.bool,
}

export default withClipboardLogic(ShareButtonLink)

import get from 'lodash/get'
import PropTypes from 'prop-types'
import TelegramOIcon from 'public/assets/svg/telegram-o.svg'

const ShareButtonTwitter = ({ shareItem }) => {
  const shareText = get(shareItem, 'text')
  const shareUrl = get(shareItem, 'url')

  const shareHref =
    shareText && shareUrl
      ? `https://telegram.me/share/url?url=${shareUrl}&text=${shareText}`
      : false

  return (
    <a
      className="telegram share-button"
      target="_blank"
      rel="noopener noreferrer"
      data-size="large"
      title={get(shareItem, 'text')}
      href={shareHref}
    >
      <TelegramOIcon />
    </a>
  )
}

ShareButtonTwitter.propTypes = {
  shareItem: PropTypes.object.isRequired,
}

export default ShareButtonTwitter

import Breadcrumbs from 'components/Common/Breadcrumbs'
import withArticleLevel from 'hocs/article-level/with-article-level'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import last from 'lodash/last'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { color, remCalc, fontFamily } from 'styles/mixins'
import { getSectionByName, hasSparkSection } from 'utils/section'

const StyledBreadcrumbs = styled(Breadcrumbs)`
  font-family: ${fontFamily.gothic};
  font-weight: bold;
  font-size: ${remCalc(13)};
  line-height: ${remCalc(16)};
  text-transform: uppercase;
  color: ${(props) => props.theme.highlightColor};
  ${(props) =>
    props.$isSpark &&
    `
    color: ${color.white};
    background-color: ${color.mediumPurple};
    width: fit-content;
    padding: 0 ${remCalc(5)};
  `}
`

const ArticleLevelLabel = ({ className, node, variant }) => {
  const { sections, ypTopics } = node

  // Label for Section Index:
  // [Sub-section]/[Topic] (if topic is available)
  // [Sub-section] (if no topic is available)

  // Label for the section pages without sub-section (e.g. Available assignments, Career advice, Events)
  // Display [Topic] (if topic is available)
  // Display [Section] (if no topic is available)

  // special handling for Spark
  //
  // when: sub-section has Spark
  // then: hide topic label
  //
  // when: variant is search-page and sub-section has Spark
  // then: override above logic and show Spark label only

  const breadCrumbsItems = []

  const firstTopic = get(ypTopics, '[0]', {})
  const lastSection = last(get(sections, '0', []))

  const sparkSectionConfig = getSectionByName('spark')
  const isSpark = hasSparkSection(sections)

  // search page, show spark articles but only show Spark section link
  if (variant === 'search-page' && isSpark) {
    const { name, path, entityUuid } = sparkSectionConfig
    return (
      <StyledBreadcrumbs
        className={className}
        $isSpark={isSpark}
        items={[
          {
            text: name,
            href: path,
            entityUuid,
            isSection: true,
          },
        ]}
      />
    )
  }

  if (!isEmpty(lastSection)) {
    const { name, urlAlias, entityUuid } = lastSection
    breadCrumbsItems.push({
      text: name,
      href: urlAlias,
      entityUuid,
      isSection: true,
    })
  }

  // if article isSpark, hide topic link
  if (!isSpark && !isEmpty(firstTopic) && get(lastSection, 'name') !== get(firstTopic, 'name')) {
    const { name, urlAlias, entityUuid } = firstTopic
    breadCrumbsItems.push({
      text: name,
      href: urlAlias,
      entityUuid,
      isTopic: true,
    })
  }

  return <StyledBreadcrumbs className={className} items={breadCrumbsItems} />
}

ArticleLevelLabel.propTypes = {
  node: PropTypes.object,
  variant: PropTypes.string,
}

export default withArticleLevel(ArticleLevelLabel)

import isArray from 'lodash'

import createPropType from './create-prop-types'

module.exports = (length) =>
  createPropType(
    `array-length-${length}`,
    (prop, { length }) => {
      return isArray(prop) && prop.length >= length
    },
    { length },
  )

import isArray from 'lodash/isArray'
import reduce from 'lodash/reduce'

import createPropType from './../create-prop-types'
import isUuid from './isUuid'

module.exports = createPropType('uuid-array', (prop) => {
  if (!isArray(prop)) return false
  return reduce(prop, (result, value) => result && isUuid(value), true)
})

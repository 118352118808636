import { useQuery } from '@apollo/client'
import { GET_QUEUE_ITEMS_BY_NAME } from 'apollo/queries/cs'
import dropRight from 'lodash/dropRight'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import without from 'lodash/without'
import { useMemo, useState } from 'react'

const useFetchQueueItems = ({
  queueName,
  offset = 0,
  limit = 5,
  excludeEntityUuids,
  ssr = true,
  isLazy = false,
  overrideQuery = false,
  overrideGetContext = false,
  overrideConcatContext = false,
}) => {
  const option = {
    name: queueName,
    itemLimit: limit,
    offset: offset,
    excludeEntityUuids,
  }

  const [called, setCalled] = useState(!isLazy && ssr)
  const [skip, setSkip] = useState(isLazy)
  const [stateOffset, setStateOffset] = useState(isLazy ? offset : limit)
  const [stateContext, setStateContext] = useState([])

  const { loading, fetchMore, data } = useQuery(overrideQuery || GET_QUEUE_ITEMS_BY_NAME, {
    variables: option,
    notifyOnNetworkStatusChange: true,
    ssr,
    skip,
    onCompleted: () => {
      if (!called) setCalled(true)
    },
  })

  const getContext = overrideGetContext || ((data) => get(data, 'queue.items', []))

  const concatContext =
    overrideConcatContext ||
    ((stateDataContext, newDataContext) => [...stateDataContext, ...newDataContext])

  const result = useMemo(() => {
    return isEmpty(stateContext) ? getContext(data) : stateContext
  }, [data, stateContext])

  const fetchNext = ({ nextLimit = limit, callback = () => {} } = {}) => {
    try {
      if (skip) setSkip(false)
      fetchMore({
        variables: {
          offset: stateOffset,
          itemLimit: nextLimit + 1, // puls one for checking haveNext
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          const prevContext = isEmpty(stateContext) ? getContext(prev) : stateContext
          const context = getContext(fetchMoreResult)
          let removeDuplicate = without(context, ...prevContext)
          if (context.length > nextLimit && removeDuplicate.length > 0) {
            setStateOffset(stateOffset + nextLimit)
            callback(true)
          } else {
            callback(false)
          }
          if (removeDuplicate.length > nextLimit) {
            removeDuplicate = dropRight(removeDuplicate) // remove puls one for checking haveNext
          }
          const newContext = concatContext(prevContext, removeDuplicate)
          setStateContext(newContext)
        },
      })
    } catch (error) {
      callback(false)
    }
  }

  return {
    called,
    loading,
    fetchNext,
    context: result,
  }
}

export default useFetchQueueItems

import withFacebookLogic from 'hocs/with-facebook-logic'
import get from 'lodash/get'
import has from 'lodash/has'
import PropTypes from 'prop-types'
import FacebookOIcon from 'public/assets/svg/facebook-o.svg'

const ShareButtonFacebook = ({ shareItem }) => {
  const shareText = get(shareItem, 'text')
  const shareUrl = get(shareItem, 'url')

  const facebookShare = (e) => {
    if (typeof window !== 'undefined' && has(window, 'FB')) {
      e.preventDefault()
      const shareObject = {
        method: 'share',
        display: 'popup',
        quote: shareText,
        href: shareUrl,
      }
      window.FB.ui(shareObject)
    }
  }

  const shareHref =
    shareText && shareUrl
      ? `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}&src=sdkpreparse&quote=${shareText}`
      : false

  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      title={get(shareItem, 'text')}
      href={shareHref}
      onClick={facebookShare}
    >
      <FacebookOIcon />
    </a>
  )
}

ShareButtonFacebook.propTypes = {
  shareItem: PropTypes.object.isRequired,
}

export default withFacebookLogic(ShareButtonFacebook)
